// .ant-modal-close-x{
//     padding-right: 17px;
//     padding-top: 13px
// }
// .ant-image-preview-footer
.enlargeButton {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: white;
    padding: 5px;
    border-radius: 15px;
    opacity: 0.7;
    transition: opacity 0.5s ease, padding 0.5s ease;
}

.enlargeButton:hover {
    opacity: 1;
    background-color: white;
    padding: 5px 10px 5px 5px;
}

.enlargeButton .iconStyle {
    font-size: 24px;
    color: black;
    transition: transform 0.5s ease;
}

.hoverText {
    display: none;
    color: black;
}

.enlargeButton:hover .hoverText {
    display: inline;
}

.ant-modal-content{
    position: fixed;
    z-index: 0;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 0.9;
}

.NotifyModal .ant-modal-content{
        opacity: 1;
}

.slick-slide.slick-active {
    // display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    opacity: 1 !important;
}
.ant-image .ant-image-mask{
    background:none !important ;
}
.ant-image .ant-image-mask .ant-image-mask-info{
    display: none !important;
}
.ant-image-preview-mask {
    background-color: white !important;
    padding: 30px !important;
    /* White with slight opacity */
}
.ant-image-preview-operations-operation>.anticon{
    color: white !important;
}
.ant-image-preview-operations{
    background-color: #6866667a !important;
}
.static-overlay{
        position: absolute;
        inset: 0;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.3s;
}