// Color Pallete 🖌

@import "../../../../../styles/Colors.scss";

///////////////////////////////////////////

.OnlyForYouCard {
  width: 100px;
  border: "1px solid #C4C4C4";
  border-radius: "0px";
  padding: 5px;
  .ant-card {
    display: block;
    border: none;
    align-items: center;
    background-color: transparent !important;

    .ant-card-body {
      padding: 0px !important;
      .ant-card-meta-detail {
        text-align: center;
      }
      .ant-card-meta-title {
        text-overflow: revert;
        overflow: revert;
        white-space: revert;
        margin-bottom: -5px !important;

        .product_title {
          color: $productTitle;
          line-height: 16px;
          height: 35px;
          display: block;
          overflow: hidden;
        }
      }
    }

    .ant-card-cover {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;

      .ant-image {
        img {
          border-radius: 0px;
          object-fit: cover;
        }
      }

      .outOfStock_container {
        position: absolute;
        z-index: 1;
        // top: 165px;
        // left: 0px;
        width: 100%;

        .outOfStock_tag {
          background-color: $outOfStockTagBg;
          font-size: 16px;
          color: $surface;
          width: 100%;
          display: block;
          font-family: "ZemestroStd-Medium" !important;
          font-weight: 100 !important;
          height: 30px;
        }
      }

      .mobile_outOfStock_container {
        .outOfStock_tag {
          background-color: $outOfStockTagMobileBg;
          height: 20px;
        }
      }
    }

    .mobile_discount_tag {
      background-color: $discountTagColor !important;

      border-radius: 100px;

      .ant-typography {
        color: $white;
        font-size: 10px;
        // font-weight: 600;
      }
    }

    .ant-space {
      gap: 2px !important;
      .ant-space-item {
        height: 18px;
      }
      .product_price {
        // opacity: 1;
        // font-size: 12px;
        padding-top: 5px;

        span {
          color: var(--background-color);
          // font-size: 16px;
          // font-weight: 600;
          font-family: "ZemestroStd-Medium" !important;
        }
      }

      .product_tags_container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-color)
          var(--scrollbar-background-color);

        .onlineOnly_tag {
          background-color: $onlineOnlyTagBg;
          color: $secondaryTwo;

          .ant-typography {
            font-size: 11px;
            font-family: "ZemestroStd-Medium" !important;
            font-weight: 100 !important;
          }
        }

        .freeShipping_tag {
          background-color: $freeShippingTagBg;
          color: $secondaryTwo;

          .ant-typography {
            font-size: 11px;
            font-family: "ZemestroStd-Medium" !important;
            font-weight: 100 !important;
          }
        }
      }
    }
  }
}
