.similar-products-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scroll-buttons {
  display: flex;
  gap: 10px;

  .scroll-button {
    background-color: #f5f5f5;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .scroll-icon {
      width: 15px;
      height: 15px;
    }
  }
}

.product-scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 20px 0px 20px 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

.product-card-wrapper {
  flex: 0 0 24%;
  max-width: 24%;
  box-sizing: border-box;
}

.product-carousel-main {
  margin: auto;
  max-width: 100%;
}
